import React, { memo, Suspense, useState } from 'react';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { Popover, Typography } from '@mui/material';
import TextAction from '../commons/TextAction';
import styled from "@emotion/styled";
import { memoize } from 'lodash';
import { areEqual, FixedSizeList as List } from 'react-window';
import { fi } from '../../utils/helpers';
import { FilterAlt } from '@mui/icons-material';
import PageLoader from '../Loader/PageLoader';
import { RecoilState, useRecoilState } from 'recoil';

const FilterWrapper = styled.span`
  cursor: pointer;
  opacity: 0.1;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.7;
  }

  &.active {
    opacity: 0.9;
    color: var(--color-blue)
  }
`;

const FilterWrapperElement = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 240px;
  min-height: 50px;
  border: 1px inset solid var(--color-border-light);
  user-select: none;

  h6 {
    background-color: var(--color-background);
    font-family: var(--font-semi-bold);
    font-size: 16px;
    padding: 8px;
    display: block;
    width: 100%;

    span {
      float: right;
    }
  }

  label {
    font-size: 14px;
    font-family: var(--font-regular);
  }
`;

const FilterComponentWrapper = styled.div`
  position: relative;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
`;

const ListRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  padding: 0 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  label {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  label, input {
    cursor: pointer;
  }

  &.selected {
    background-color: var(--color-selected);
  }

  &:hover {
    background-color: var(--color-box-shadow);
  }
`;

const Row = memo(({ data, index, style }: any) => {
    const {items, toggleItemActive} = data;
    const item = items[index];

    return (
        <ListRow style={style} className={fi(item.isActive, 'selected')} title={item.label}>
            <input type={'checkbox'} onChange={(evt) => toggleItemActive(item, evt.target.checked)}
                   checked={item.isActive} id={`checkbox-${index}`}/>
            <label htmlFor={`checkbox-${index}`}>{item.label}</label>
        </ListRow>
    )
}, areEqual)

const createItemData = memoize((items, toggleItemActive) => ({
    items,
    toggleItemActive,
}));

const ColumnListFilter = ({ list, cb }: { list: string[], cb: (selected) => void}) => {
  const [selected, setSelected] = useState<string[]>([])
  
  const updateSelected = (updatedSelected) => {
    setSelected(updatedSelected)
    cb(updatedSelected)
  }

  const onClearFilter = () => {
    updateSelected([])
  }

  const toggleItem = (item, val) => {
    const selectedTmp: string[] = [...selected]
    if (!val) {
      selectedTmp.splice(selectedTmp.indexOf(item.value), 1)
    } else {
      selectedTmp.push(item.value)
    }
    updateSelected(selectedTmp)
  }

	const data = createItemData(list.map(i => ({
		label: i,
		value: i,
		isActive: selected.includes(i),
		toggleItem
	})), toggleItem)

	const popupState = usePopupState({variant: 'popover', popupId: 'column-filter-popover'});

    return (
    <>
        <FilterWrapper {...bindTrigger(popupState)} className={selected.length ? 'active' : ''}>
            <FilterAlt />
            {
              fi(
                selected.length, 
                <span className="count">{ selected.length }</span>
              )
            }
        </FilterWrapper>
        <Popover {...bindPopover(popupState)}>
            <FilterWrapperElement>
                <Typography variant={'h6'}>Filter column <TextAction className={!selected.length ? 'disabled' : ''} onClick={onClearFilter}
                                                    data-testid={'clear-filter-action'}>Clear
                filter</TextAction></Typography>
                <FilterComponentWrapper>
                    <Suspense fallback={<PageLoader size={24}/>}>
                        <ListWrapper>
                        <List
                            height={150}
                            itemSize={35}
                            itemData={data}
                            itemCount={data.items.length}
                            width={240}>
                            {Row}
                        </List>
                        </ListWrapper>
                    </Suspense>
                </FilterComponentWrapper>
            </FilterWrapperElement>
        </Popover>
    </>
    )
}

export default ColumnListFilter;
