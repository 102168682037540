import React, { Suspense, useEffect, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import BulkActions from './BulkActions';
import BulkFiles from './BulkFiles';
import { BulkFile } from '../../cms/models/BulkFile';
import CellHighlight from './CellHighlight';
import CellForm from './CellForm';
import { ConfigKey, DefaultColumnWidths, selectedRows, BulkTableColumnsState, bulkTableFilterAtom, bulkTableState, SuccessfulBulkFileMessage } from './state';
import { sessionAtom } from "../../state/session";
import { transaction } from '../../state/recoilNexus';
import { cacheBuster } from '../../state/state';
import { useLocalStorage } from '../../utils/hooks';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import ColumnListFilter from './ColumnListFilter';

const Wrapper = styled.div`
    box-shadow: 0 4px 6px 0 var(--color-box-shadow);
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-border-light);
    position: relative;
    background-color: white;
`;


const TableWrapper = styled.div`
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
`;

const Table = styled.table`
    width: calc(100% - 1px);
    border-collapse: collapse;
    background-color: white;
    border: 1px solid #ddd;
    user-select: none;

    tr {
        &.selected {
            background-color: var(--color-background);
        }
    }

    td, th {
        border: 1px solid var(--color-border-light);

        &:first-of-type {
            text-align: center;
            width: 30px;
        }
    }

    th {
        padding: 8px;
        position: relative;
		white-space: nowrap;
    }

    th {
        font-weight: bold;

		&.status {
			display: flex;
			align-items: center;
		}

        span {
            &.mandatory:after {
                content: '(mandatory)';
                margin-left: 2px;
                vertical-align: super;
                font-size: 10px;
                color: red;
            }
        }
    }
`;

const TableBody = styled.tbody`
    &.over {
        border: 2px dashed var(--color-blue);

        td > * {
            pointer-events: none;
        }
    }
`;

const Resizer = styled.div`
    position: absolute;
    width: 9px;
    right: -5px;
    top: 1px;
    bottom: 1px;
    z-index: 1;
    cursor: ew-resize;
`;

const BulkTable = ({
	onUpload,
	columns,
}: {
	onUpload: (list: FileList) => void;
	columns: BulkTableColumnsState[];
}) => {
	const [selected] = useRecoilState(selectedRows);
	const session = useRecoilValue(sessionAtom);
	const businessStream = session?.selectedBusinessStream || 'default';
	const [cfg, setCfg] = useLocalStorage(ConfigKey[businessStream], DefaultColumnWidths);
	const tableRef = useRef<any>();
	const bulkFileAll = useRecoilValue(cacheBuster(`bulk-file-all`));
    const tableState = useRecoilValue(bulkTableState)
	const [bulkTableFilter, setBulkTableFilter] = useRecoilState(bulkTableFilterAtom)

    const statusList = useMemo<string[]>(() => {
		const sl = BulkFile.errorList()
		const stats = BulkFile.bulkFileUploadStatus()
		if (sl.length > 0 && stats.successful) {
			sl.push(SuccessfulBulkFileMessage)
		}
        return sl
    }, [bulkFileAll, tableState])

	useEffect(() => {
		return () => {
			BulkFile.files = [];
		};
	}, []);

	// Double-click on the column separator.
	// Iterates all the cells in the column from top to bottom and determine the maximum width the column
	// should have and resizes the column to that width.
	const onDoubleClick = (event: any, field: string, cellIndex: number) => {
		const column = ['filename', ...BulkFile.columns][cellIndex - 1];
		if (!column) return;

		const parent = event.target.closest('table');
		if (!parent) {
			return;
		}

		const elements = parent.querySelectorAll(
			`tbody>tr>td:nth-child(${cellIndex + 1})`,
		);

		const tmp: any = document.createElement('div');
		tmp.style = 'white-space: nowrap; display:inline-block;';
		parent.parentElement.appendChild(tmp);
		let max = 0;
		elements.forEach((elem) => {
			tmp.innerHTML = elem.innerHTML;
			max = Math.max(tmp.getBoundingClientRect().width, max);
			tmp.innerHTML = '';
		});

		max = Math.round(max) + 34;

		setCfg((val) => {
			const newCfg = val.slice();
			newCfg[cellIndex - 1] = max;
			return newCfg;
		});

		parent.parentElement.removeChild(tmp);
		if (max > 0) {
			const head = parent.querySelector(
				`thead>tr>th:nth-child(${cellIndex + 1})`,
			);
			if (head) {
				head.style.width = `${max}px`;
				head.style.minWidth = `${max}px`;
			}
		}
	};

	const onMouseDown = (event: any, cell: any) => {
		const parent = event.target.closest('th');
		const originalWidth = parent.getBoundingClientRect().width;

		let thisMouseUp;
		let thisMouseMove;
		let startX = -1;
		let width = 0;

		const mouseMove = (evt) => {
			if (startX === -1) {
				startX = evt.pageX;
				return;
			}
			const delta = evt.pageX - startX;
			width = originalWidth + delta;
			parent.style.minWidth = width + 'px';
			parent.style.width = width + 'px';
		};
		thisMouseMove = mouseMove.bind(this);

		const mouseUp = (_evt) => {
			if (width) {
				const column = ['file', ...BulkFile.columns].indexOf(cell);
				setCfg((val) => {
					const newCfg = val.slice();
					newCfg[column] = Math.round(width);
					return newCfg;
				});
			}
			window.removeEventListener('mousemove', thisMouseMove);
			window.removeEventListener('mouseup', thisMouseUp);
			startX = -1;
		};
		thisMouseUp = mouseUp.bind(this);

		window.addEventListener('mousemove', thisMouseMove);
		window.addEventListener('mouseup', thisMouseUp);
	};

	const resizer = (field: string, index: number) => {
		return (
			<Resizer
				onDoubleClick={(event) => onDoubleClick(event, field, index)}
				onMouseDown={(event) => onMouseDown(event, field)}
			/>
		);
	};

	const toggleSelect = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const checked = evt.target.checked;
		transaction(({set}) => {
			if (!checked) {
				set(selectedRows, []);
			} else {
				set(
					selectedRows,
					BulkFile.files.map((file) => file.bulkid),
				);
			}
			BulkFile.files.forEach((file) => {
				file.selected = checked;
				set(
					cacheBuster(`bulk-file-${file.filename}`),
					(val) => val + 1,
				);
			});
		});
	};

	return (
		<Wrapper data-testid="bulk-table-wrapper" id="bulk-table-wrapper">
			<BulkActions onUpload={onUpload} />
			<TableWrapper>
				<Table data-testid="bulk-table" id="bulk-table">
					<thead data-testid="bulk-table-header">
						<tr>
							<th>
								<input
									checked={
										BulkFile.files.length > 0 &&
										selected.length ===
											BulkFile.files.length
									}
									type="checkbox"
									onChange={(evt) => toggleSelect(evt)}
								/>
							</th>
							{columns.filter(column=> column.visible === true).map((field, index) => (
								<th
									key={index}
									style={{
										width: `${cfg[index]}px`,
										minWidth: `${cfg[index]}px`,
									}}
								>
									<span
										className={
											field.mandatory === true
												? 'mandatory'
												: ''
										}
									>
										{field.label}
									</span>
									{resizer(field.name, index + 1)}
								</th>
							))}
							<th className='status'>
								<span>Status</span>
								<ColumnListFilter list={statusList} cb={(selected) => { setBulkTableFilter({ ...bulkTableFilter, statuses: selected }) }} />
							</th>
						</tr>
					</thead>
					<TableBody ref={tableRef} data-testid="bulk-table-body">
						<Suspense
							fallback={
								<tr>
									<td>Loading...</td>
								</tr>
							}
						>
							<BulkFiles />
						</Suspense>
					</TableBody>
				</Table>
				<CellHighlight />
				<CellForm />
			</TableWrapper>
		</Wrapper>
	);
};

export default BulkTable;
