import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import {useRecoilValue} from 'recoil';
import {productDataSelector} from '../../../../../state/state';
import styled from '@emotion/styled';
import {fi} from '../../../../../utils/helpers';
import {Field} from '../../../../../cms/models/__ModelInfo';
import Spacer from '../../../../commons/Spacer';
import Checkbox from '@mui/material/Checkbox';
import CheckBox from '@mui/icons-material/CheckBox';
import CloseRounded from '@mui/icons-material/CloseRounded';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {flattenTree, generateTree, Node, NodeMap, NodesMap, NodesMapType, selectDown, selectUp} from './utils';
import TreeNode from './TreeNode';
import {useForm, useFormField} from '../../../state';
import {DisplayMode} from '../../../../../cms/models/__CMSObject';
import {QualificationGroupMapping} from '../../../../../cms/models/QualificationGroupMapping';
import {SubjectGroupMapping} from '../../../../../cms/models/SubjectGroupMapping';
import {Assessment} from '../../../../../cms/models/Assessment';
import {QualificationSize} from '../../../../../cms/models/QualificationSize';
import {Component} from '../../../../../cms/models/Component';
import {ChildAssessment} from '../../../../../cms/models/ChildAssessment';
import {CTUnit} from '../../../../../cms/models/CTUnit';
import {Objects} from '../../../../../utils/objects';
import {Lists} from '../../../../../utils/lists';

const Wrapper = styled.div`
    position: relative;

    &.disabled {
        pointer-events: none;

        span {
            opacity: 0.5;
        }
    }
`;

const RowLabel = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    label {
        margin-bottom: 0;
    }

    &.disabled {
        opacity: 0.3;
        cursor: default;
        pointer-events: none;
    }

    .MuiButtonBase-root {
        padding: 0 9px 0;
    }
`;

const ValueBox = styled.div`
    display: flex;
    align-items: stretch;
    background: white;
    padding: 8px 0 8px 8px;
    border: 1px solid var(--color-border);
    border-radius: 4px;

    &.error {
        border-color: var(--color-red)
    }
`;

const TagBox = styled.div`
    flex-grow: 1;
    max-width: 100%;
    flex-wrap: wrap;
    display: flex;

    span {
        cursor: default;
        border: none;
        overflow: hidden;
        padding: 3px;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover {
            background: var(--color-background);
        }

        &:after {
            content: ",";
            margin-right: 8px;
        }

        &:last-of-type {
            &:after {
                content: "";
                margin-right: 0;
            }
        }
    }
`;

const ActionBox = styled.div`
    display: flex;
    align-items: stretch;

    .clear {
        display: flex;
        justify-content: center;
        justify-items: center;
        align-items: center;
        transition: color 150ms ease 0s;
        padding: 0 8px;

        svg {
            width: 18px;
            height: 18px;
            fill: rgb(204, 204, 204);

            &:hover {
                fill: rgb(153, 153, 153);
            }
        }
    }

    .expand {
        border-left: 1px solid rgb(204, 204, 204);
        color: rgb(204, 204, 204);
        transition: color 150ms ease 0s;
        width: 36px;
        display: flex;
        justify-content: center;
        justify-items: center;
        align-items: center;
        margin: 2px 0;

        svg {
            width: 22px;
            height: 22px;
        }

        &:hover {
            color: rgb(153, 153, 153);
        }
    }
`;

const TreeList = styled.div`
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    //top: 100%;
    margin-top: 6px;

    height: 300px;

    display: flex;
    flex-direction: column;
    background: white;
    border: 1px solid var(--color-border);
    border-radius: 4px;
`;

const SearchWrapper = styled.div`
    box-sizing: border-box;
    display: flex;

    input {
        flex-grow: 1;
        box-sizing: border-box;
        border: none;
        padding: 8px 4px;
        margin: 8px;
    }
`;

const List = styled.div`
    flex-grow: 1;
    max-height: 300px;
    position: relative;
    overflow: auto;

    & > div {
        width: 0;
    }

    & > ul {
        position: absolute;
        left: 0;
        right: 0;

        li {
            height: 30px;
            display: flex;
            flex-direction: row;
            justify-items: center;
        }
    }
`;

type IProductDataSelect = {
	field: Field,
	readonly?: boolean,
	nodes?: string
}

let alignTop = false;

const ProductDataSelect = (props: IProductDataSelect) => {
	const form = useForm();
	const field = useFormField(props.field.uid);

	const tree = useRecoilValue(productDataSelector);
	const [root, setRoot] = useState<Node>({id: '', children: []} as any as Node);
	const [items, setItems] = useState<Node[]>([]);
	const listRef = useRef<HTMLDivElement>(null);
	const ref = useRef(null);
	const [expanded, setExpanded] = useState(false);
	const [query, setQuery] = useState('');
	const portalRef = useRef<HTMLDivElement>(document.getElementById('portal-wrapper') as HTMLDivElement);

	const menuObserver: any = useRef({});

	const nodesMap: NodesMapType = useMemo(() => {
		if (props.nodes) {
			if (!NodesMap[props.nodes]) {
				const nodesTree: NodesMapType = {}
				generateTree(tree, props.field.flags.subjectOnly, nodesTree);
				NodesMap[props.nodes] = nodesTree
			}
			return NodesMap[props.nodes]
		}
		return NodeMap
	},[props, tree])

	const setInitialState = () => {
		let r = nodesMap[''];
		if (!props.nodes) {
			r = generateTree(tree, props.field.flags.subjectOnly);
		}
		for (let k in nodesMap) {
			const obj = nodesMap[k];
			obj.selected = false;
			obj.expanded = false;
			obj.selectedChildren = 0;
		}

		for (let k in nodesMap) {
			const obj = nodesMap[k];
			if (props.field.flags.subjectOnly) {
				if (obj.object instanceof SubjectGroupMapping && Lists.default(form.state.values.qualification_mapping).includes(obj.id)) {
					obj.selected = true;
					selectUp(obj);
				}
			} else {
				if (obj.object instanceof Component && Lists.default(form.state.values.component).includes(obj.id)) {
					obj.selected = true;
					selectUp(obj);
				}
				if (obj.object instanceof ChildAssessment && Lists.default(form.state.values.child_assessment).includes(obj.id)) {
					obj.selected = true;
					selectUp(obj);
				}
				if (obj.object instanceof CTUnit && Lists.default(form.state.values.ct_unit).includes(obj.id)) {
					obj.selected = true;
					selectUp(obj);
				}
			}
		}
		setRoot(r);
		setItems(flattenTree(r));
	};

	useEffect(() => {
		if (!tree) {
			return;
		}
		setInitialState();
		// eslint-disable-next-line
	}, [tree, nodesMap]);

	useEffect(() => {
		setInitialState();
		// eslint-disable-next-line
	}, [form.state.object, nodesMap]);

	const onClearAll = (evt) => {
		evt.preventDefault();
		evt.stopPropagation();
		for (let k in nodesMap) {
			const obj = nodesMap[k];
			obj.selected = false;
			obj.selectedChildren = 0;
		}
		setItems(flattenTree(root));
		const tmp: any = {};
		if (props.field.flags.subjectOnly) {
			tmp.qualification_group_mapping = [];
			tmp.qualification_mapping = [];
		} else {
			tmp.qualification = [];
			tmp.qualification_group = [];
			tmp.specification_group = [];
			tmp.subject = [];

			tmp.assessment = [];
			tmp.child_assessment = [];
			tmp.component = [];
			tmp.qualification_size = [];
			tmp.ct_unit = [];
		}
		tmp.productData = '';
		form.setValues(tmp);
	};

	const onSearch = (evt) => {
		const query = evt.target.value.toLowerCase();
		if (!query || evt.key === 'Escape') {
			evt.target.value = '';
			setQuery('');
			setItems(flattenTree(root));
		} else if (evt.type === 'change') {
			setQuery(query);
			const res: Node[] = [];
			for (let k in nodesMap) {
				const obj = nodesMap[k];
				if (obj.object.displayLabel(DisplayMode.DETAILED).toLowerCase().includes(query)) {
					res.push(obj);
				}
			}
			setItems(res);
		}
	};

	const onSelectAll = (checked) => {
		for (let k in nodesMap) {
			const obj = nodesMap[k];
			obj.selected = checked;
			obj.selectedChildren = fi(checked, obj.children.length, 0);
		}

		setItems(flattenTree(root));
		setExpanded(false);
		setQuery('');
		setItems(flattenTree(root));

		const tmp: any = {
			all_assessments: checked,
		};
		if (props.field.flags.subjectOnly) {
			tmp.qualification_group_mapping = [];
			tmp.qualification_mapping = [];
		} else {
			tmp.assessment = [];
			tmp.child_assessment = [];
			tmp.component = [];
			tmp.qualification_size = [];
			tmp.ct_unit = [];
			tmp.qualification = [];
			tmp.qualification_group = [];
			tmp.subject = [];
		}

		tmp.productData = fi(checked, 'all_assessments', '');
		form.setValues(tmp);
	};

	const setPosition = () => {
		const input = ref.current as any as HTMLDivElement;
		if (input) {
			const box = input.getBoundingClientRect();
			portalRef.current.style.left = `${box.left}px`;
			portalRef.current.style.width = `${box.width}px`;
			if (alignTop) {
				portalRef.current.style.top = `${box.top - 300 - 16}px`;
			} else {
				portalRef.current.style.top = `${box.top + box.height + 4}px`;
			}
		}
	};

	const resetObserver = () => {
		if (menuObserver.current && menuObserver.current.disconnect) {
			menuObserver.current.disconnect();
		}
	};

	const setPortalPosition = useCallback(() => {
		const observeOnscreen = (entries: IntersectionObserverEntry[], _observer: IntersectionObserver): void => {
			const {boundingClientRect, intersectionRect} = entries[0];
			if (boundingClientRect.height !== intersectionRect.height) {
				alignTop = boundingClientRect.height > intersectionRect.height;
			}
			if (boundingClientRect.y < 0 && alignTop) {
				alignTop = false;
			}
			setPosition();
		};

		const menuList = listRef.current;
		if (!menuList) {
			return;
		}

		setPosition();
		resetObserver();
		menuObserver.current = new IntersectionObserver(observeOnscreen);
		menuObserver.current.observe(menuList);
		// eslint-disable-next-line
	}, [ref, portalRef, listRef]);

	const onExpand = (evt) => {
		evt.preventDefault();
		evt.stopPropagation();
		if (form.state.values.all_assessments) {
			return;
		}

		setExpanded((val) => !val);
	};

	const selectedValue = useMemo(() => {
		if (form.state.values.all_assessments || form.state.values.productData === 'all_assessments') {
			return [<span key={'all'} style={{opacity: 0.4}}>All products selected</span>];
		}
		const list: any[] = [];
		const added: any = {};
		let idx = 0;
		const walk = (node: Node) => {
			if (node.selected) {
				if (!added[node.id]) {
					list.push(<span key={`{node.id}-${idx}`}>{node.object.displayLabel(DisplayMode.SHORT)}</span>);
					idx++;
					added[node.id] = true;
				}
			} else {
				for (let k in node.children) {
					walk(node.children[k]);
				}
			}
		};
		root.children.forEach(walk);
		return list;
		// eslint-disable-next-line
	}, [field, root]);

	const toggleSelectNode = (node: Node) => {
		node.selected = !node.selected;
		const baseNodes = selectDown(node, props.field.flags.subjectOnly);
		baseNodes.forEach(n => {
			selectUp(n);
		});
	};

	const onSelect = (node: Node) => {
		toggleSelectNode(node);
		if (!query) {
			setItems(flattenTree(root));
		}
		let val: string[];
		const tmp: any = {};

		if (props.field.flags.subjectOnly) {
			tmp.qualification_group_mapping = [];
			tmp.qualification_mapping = [];

			for (let k in nodesMap) {
				const obj = nodesMap[k];
				if (obj.object instanceof SubjectGroupMapping) {
					if (obj.selected) {
						tmp.qualification_mapping.push(obj.id);
					}
				} else if (obj.object instanceof QualificationGroupMapping) {
					if (obj.selected || obj.selectedChildren > 0) {
						tmp.qualification_group_mapping.push(obj.id);
					}
				}
			}
			val = [...tmp.qualification_mapping, tmp.qualification_group_mapping];
		} else {
			tmp.assessment = [];
			tmp.child_assessment = [];
			tmp.component = [];
			tmp.qualification_size = [];
			tmp.ct_unit = [];
			tmp.qualification = [];
			tmp.qualification_group = [];
			tmp.subject = [];

			for (const obj of Object.values(nodesMap)) {
				if (!obj.selected) {
					continue;
				}

				switch (true) {
					case obj.object instanceof Assessment:
						if (obj.selectedChildren > 0) {
							tmp.assessment.push(obj.id);
						}
						break;
					case obj.object instanceof QualificationSize:
						if (obj.selectedChildren > 0) {
							tmp.qualification_size.push(obj.id);
						}
						break;
					case obj.object instanceof Component:
						tmp.component.push(obj.id);
						break;
					case obj.object instanceof ChildAssessment:
						tmp.child_assessment.push(obj.id);
						break;
					case obj.object instanceof CTUnit:
						tmp.ct_unit.push(obj.id);
						break;
					case obj.object instanceof QualificationGroupMapping:
						tmp.qualification.push(obj.id);
						tmp.qualification_group.push(...(obj.object as QualificationGroupMapping).qualification_group)
						break;
					case obj.object instanceof SubjectGroupMapping:
						tmp.subject.push(obj.id);
						break;
				}
			}
			val = [...tmp.assessment, ...tmp.child_assessment, ...tmp.component, ...tmp.qualification_size, ...tmp.ct_unit];
		}

		tmp.productData = val.join(',');
		form.setValues(tmp);
	};

	// expand & collapse
	const onToggle = (node: Node) => {
		if (node.children.length === 0) {
			return;
		}
		node.expanded = !node.expanded;
		setItems(flattenTree(root));
	};

	useEffect(() => {
		if (!expanded) {
			resetObserver();
			portalRef.current.style.height = '0px';
		} else {
			setPortalPosition();
			portalRef.current.style.height = '300px';
		}
		const checkOutsideClick = e => {
			if (expanded && listRef.current && !Objects.default(listRef.current).contains(e.target)) {
				setExpanded(false);
				setQuery('');
				setItems(flattenTree(root));
			}
		};
		document.addEventListener('mousedown', checkOutsideClick);
		return () => {
			resetObserver();
			document.removeEventListener('mousedown', checkOutsideClick);
		};
		// eslint-disable-next-line
	}, [expanded]);

	useEffect(() => {
		const onScroll = () => {
			setPortalPosition();
		};
		const tmp = portalRef.current;
		tmp.style.height = '0px';
		document.getElementsByTagName('main')[0].addEventListener('scroll', onScroll);
		window.addEventListener('scroll', onScroll);
		window.addEventListener('resize', onScroll);

		return () => {
			tmp.style.height = '0px';
			document.getElementsByTagName('main')[0].removeEventListener('scroll', onScroll);
			window.removeEventListener('scroll', onScroll);
			window.removeEventListener('resize', onScroll);
		};
		// eslint-disable-next-line
	}, []);

	return (
		<Wrapper data-testid='product-mapping-tree' className={fi(field.previewMode, 'disabled', '')}>
			<RowLabel className={fi(props.readonly, 'disabled')}>
				<label className={fi(props.field.flags.required, 'required')}>{props.field.name}</label>
				<Spacer />
				<Checkbox
					className='form-checkbox'
					aria-label={`select-all`}
					id={`select-all`}
					name={`select-all`}
					checked={Boolean(form.state.values.all_assessments) || form.state.values.productData === 'all_assessments'}
					tabIndex={0}
					disabled={Boolean(props.readonly)|| (typeof field.disabled === "undefined"?field.isDisabled():field.disabled)}
					color='primary'
					checkedIcon={<CheckBox />}
					size='medium'
					onChange={(_, val) => onSelectAll(val)}
					title={'Select all'}
					disableRipple
				/>
				<label htmlFor={`select-all`}>Select all</label>
			</RowLabel>
			<ValueBox className={fi(field.showError(), 'error', '')} tabIndex={0} ref={ref}
					  data-testid='selected-product-data' onClick={onExpand}>
				<TagBox>
					{selectedValue}
				</TagBox>
				<ActionBox>
					{fi(!form.state.values.all_assessments && selectedValue.length,
						<span className='clear' data-testid='clear' onClick={onClearAll}>
                            <CloseRounded />
                        </span>,
					)}
					<span className='expand' data-testid='expand' onClick={onExpand}>
                        <ExpandMore />
                    </span>
				</ActionBox>
			</ValueBox>
			{fi(expanded,
				<>{createPortal(
					<TreeList data-testid='tree-list' ref={listRef}>
						<SearchWrapper>
							<input type='text' value={query} onChange={onSearch} onKeyDown={onSearch}
								   placeholder='Search...' autoFocus={true} />
						</SearchWrapper>
						<List>
							<ul>
								{items.map((node, index) => (
									<TreeNode node={node} key={index} onToggle={onToggle} query={query}
											  onSelect={onSelect} />
								))}
							</ul>
						</List>
					</TreeList>,
					portalRef.current,
				)}</>,
			)}
		</Wrapper>
	);
};

export default ProductDataSelect;