import { useForm } from '../../../components/Form/state';
import { useRecoilValue } from 'recoil';
import { editWidgetState } from '../../../state/state';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo } from 'react';
import { fi } from '../../../utils/helpers';
import { Browser } from '../../../utils/browser';
import { Strings } from '../../../utils/strings';
import { CMSObject } from '../../../cms/models/__CMSObject';
import Spacer from '../../../components/commons/Spacer';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import { DeleteButton } from './DeleteButton';
import { RenderFormFields } from '../../../components/Form/utils';

const FormActions = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FormFields = () => {
	const form = useForm();
	const editWidget = useRecoilValue(editWidgetState);
	const {enqueueSnackbar} = useSnackbar();

	const fields = useMemo(() => {
		return RenderFormFields(form.state);
		// eslint-disable-next-line
	}, [form.state.id, form.state.model.fields]);

	const onSave = useCallback((evt) => {
		evt.preventDefault();
		const name = Strings.default(form.state.classOf().title, 'Object');
		form.state.save().then((res) => {
			enqueueSnackbar(`${Strings.titleCase(name)} ${fi(form.state.createMode, 'created', 'saved')} successfully`, {variant: 'success'});
			if (form.state.createMode) {
				Browser.navigate(Strings.default((res as CMSObject).routes().edit, '/'));
			}
		}).catch((e) => {
			enqueueSnackbar(`Error saving ${name.toLowerCase()}: ` + e.message, {variant: 'error'});
		});
	}, [form, enqueueSnackbar]);

	const onCancel = useCallback((evt) => {
		evt.preventDefault();
		Browser.navigate(Strings.default(form.state.object.routes().list, '/'));
	}, [form]);

	return (
		<div>
			{fields}
			{fi(fields.length > 0,
				<FormActions>
					<DeleteButton disabled={Boolean(editWidget)}  data-testid="table-delete-action-button"/>
					<Spacer/>
					<Button variant="text" disabled={Boolean(editWidget)}  data-testid="table-cancel-action-button" color="primary"
							onClick={(e) => onCancel(e)}>Cancel</Button>
					<Button variant="contained" disabled={Boolean(editWidget)}  data-testid="table-save-action-button" color="primary"
							onClick={(e) => onSave(e)}>Save</Button>
				</FormActions>,
			)}
		</div>
	);
};
