import React, {useMemo, useState} from 'react';
import styled from '@emotion/styled';
import {ActionButton} from '../commons/ActionButton';
import Refresh from '@mui/icons-material/Refresh';
import Delete from '@mui/icons-material/Delete';
import {setRecoil} from '../../state/recoilNexus';
import {cacheBuster} from '../../state/state';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import Client from '../../cms/client';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {bulkTableState, bulkTableFilterAtom, BulkUploadStatus, selectedRows, hasUploadedFileToggle} from './state';
import {BulkFile} from '../../cms/models/BulkFile';
import {useSnackbar} from 'notistack';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import {Messages} from '../../utils/messages';
import {modalAtom, ModalWrapperProps} from '../ModalDialogs/ModalWrapper';
import MoveModal from '../ModalDialogs/MoveModal';
import {UUID} from '../../cms/types';
import Divider from "@mui/material/Divider";
import {Objects} from "../../utils/objects";
import BackupIcon from '@mui/icons-material/Backup';
import { fi } from '../../utils/helpers';

const Wrapper = styled.div`
    padding: 16px;
    display: flex;
    justify-content: space-between;

    hr {
        margin: 6px 8px;
        height: 20px;
    }

    label {
        line-height: 1;
        cursor: pointer;
    }
`;

const StatusWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;

    dl {
        display: flex;
        gap: 4px;

        dt {
            text-transform: capitalize;
        }

        dd {
            font-weight: bold;
        }
    }

    .successful {
        color: var(--color-green);
    }

    .errors {
        color: var(--color-red);
    }
`

const ActionWrapper = styled.div`
    display: flex;

    hr {
        margin: 6px 8px;
        height: 20px;
    }

    label {
        line-height: 1;
        cursor: pointer;
    }
`;



const BulkActions = ({onUpload}: { onUpload: (list: FileList) => void }) => {
    const [selectedIds, setSelectedId] = useRecoilState(selectedRows);
    const [canMove, setCanMove] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const setModalMove = useSetRecoilState(modalAtom);
    const [state, setState] = useRecoilState(bulkTableState);
    const bulkFileAll = useRecoilValue(cacheBuster(`bulk-file-all`));
    const tableState = useRecoilValue(bulkTableState)
    const filters = useRecoilValue(bulkTableFilterAtom)
    const hasUploadedFile = useRecoilValue(hasUploadedFileToggle)

    const bulkUploadStatus = useMemo<BulkUploadStatus>(() => {
        return BulkFile.bulkFileUploadStatus()
    }, [bulkFileAll, tableState, hasUploadedFile])

    const selectedItems = useMemo(() => {
        const selected = BulkFile.filteredFiles(filters).filter(f => selectedIds.includes(f.bulkid))
        const withError = Boolean(selected.find(f => !Objects.isEmpty(f.errors)));
        setCanMove(selected.length > 0 && !withError)
        return selected;
    }, [selectedIds]);

    const onDelete = () => {
        if (selectedIds.length === 0) return;
        setState(val => ({...val, selectedCell: []}))
        const bulkFileIds = BulkFile.filteredFiles(filters).map(f => f.bulkid)
        Client.deleteBulkFiles(selectedIds.filter(id => bulkFileIds.includes(id))).then(() => {
            enqueueSnackbar('Files removed', {variant: 'success'});
            BulkFile.files = BulkFile.files.filter(f => !selectedIds.includes(f.bulkid));
            setSelectedId([]);
        }).catch(() => {
            enqueueSnackbar('Error deleting files', {variant: 'error'});
        }).finally(() => {
            setRecoil(cacheBuster(`bulk-file-all`), (val) => val + 1);
        });
    };

    const onMoveSelected = () => {
        setModalMove({
            component: (props: ModalWrapperProps) => {
                return <MoveModal {...props} onConfirm={async (targetId: UUID) => {
                    const toRemove: number[] = [];
                    const promisses: any[] = [];

                    setState(val => ({...val, selectedCell: []}))

                    selectedItems.forEach(item => {
                        const data = {...item.data, folder: targetId};
                        promisses.push(Client.create(data).then(() => {
                            toRemove.push(item.bulkid);
                        }).catch((err) => {
                            item.error = err.message;
                        }))
                    })

                    Promise.all(promisses).then(() => {
                        if (toRemove.length > 0) {
                            Client.deleteBulkFiles(toRemove).finally(() => {
                                setRecoil(cacheBuster('bulkFilesList'), (val) => val + 1);
                                enqueueSnackbar(`${toRemove.length} items created and moved`, {variant: 'success'});
                            })
                        }
                    })
                }}/>;
            },
        });
    };

    const uploadFiles = (evt) => {
        onUpload(evt.target.files)
        evt.target.value = '';
    }

    return (
        <Wrapper>
            <StatusWrapper>
                {
                    fi(bulkUploadStatus.total, 
                        Object.entries(bulkUploadStatus).map(([key, value]) => {
                        return <dl className={ key }>
                            <dt>{ fi(key === "errors", 'failed with errors', key) }</dt>
                            <dd>{ value }</dd>
                        </dl>
                    }))
                }
            </StatusWrapper>
            <ActionWrapper>
                <input style={{display: "none"}} multiple={true} id={'upload'} type='file'
                    data-testid={'bulk-upload-file-picker'}
                    accept='.doc,.docx,.pdf,.csv,.zip,.ppt,.pptx,.xls,.xlsx,.xlsm,.mp3,.mp4,.wav' onChange={uploadFiles}/>
            <ActionButton
                data-testid='table-upload-files-action-button'
                title={'Upload files'}
                onClick={() => {
                }}
            >
                <label htmlFor={'upload'}><BackupIcon/></label>
            </ActionButton>

            <ActionButton
                data-testid='table-move-items-action-button'
                disabled={!canMove}
                title={Messages.MoveItems}
                onClick={onMoveSelected}
            >
                <DriveFileMoveOutlinedIcon/>
            </ActionButton>

            <Divider orientation='vertical' variant='middle' flexItem/>

            <ActionButton
                data-testid='bulk-download-template-button'
                onClick={() => {
                    Client.excelTemplate().catch();
                }}
                disabled={false}
                title={'Download Excel template'}
            >
                <SystemUpdateAltIcon/>
            </ActionButton>

            <Divider orientation='vertical' variant='middle' flexItem/>

            <ActionButton
                data-testid='bulk-refresh-button'
                onClick={() => {
                    setRecoil(cacheBuster('bulkFilesList'), (val) => val + 1);
                }}
                disabled={false}
                title={'Refresh'}
            >
                <Refresh/>
            </ActionButton>


            <ActionButton
                data-testid='bulk-delete-selected-button'
                onClick={onDelete}
                disabled={selectedIds.length === 0}
                title={'Delete selected files'}
            >
                <Delete/>
            </ActionButton>
        </ActionWrapper>
        </Wrapper>

    );
};

export default BulkActions;