import { FieldType, ModelInfo } from './__ModelInfo';
import { FormEvent, IFormEvents } from '../../components/Form/model';
import { formField, linkedFormField } from '../../utils/decorators';
import { Types } from '../types';
import { generateYearRange } from '../../utils/helpers';
import { Strings } from '../../utils/strings';
import { ProductMapped } from './__ProductMapped';
import { getRecoil } from "../../state/recoilNexus";
import { selectedTableItemsSelector } from "../../components/TableComponent/state";
import { sessionAtom } from '../../state/session';
import { OCR_STREAM } from '../../utils/constants';

const Model = new ModelInfo({
	model: {
		uid: Types.BULK_EDIT,
		name: 'Bulk edit',
	},
	fields: [],
});

const seriesOptions = [
	Strings.localizedTxt('JANUARY'),
	Strings.localizedTxt('JUNE'),
	Strings.localizedTxt('NOVEMBER')
]

const seriesOptionsCI = [
	Strings.localizedTxt('MARCH'),
	Strings.localizedTxt('JUNE'),
	Strings.localizedTxt('NOVEMBER')
]

export class BulkEdit extends ProductMapped implements IFormEvents {
	public static model = Model;

	@formField({
		fieldtype: FieldType.Boolean,
		name: 'Title',
		group: 'group1',
		order: 1,
	})
	public editTitle: boolean = false;

	@formField({
		fieldtype: FieldType.Text,
		name: 'Title',
		order: 21,
	})
	@linkedFormField('editTitle')
	public title: string = '';

	@formField({
		fieldtype: FieldType.Boolean,
		name: 'Description',
		group: 'group1',
		order: 2,
	})
	public editDescription: boolean = false;

	@formField({
		fieldtype: FieldType.Text,
		config: { isMultiline: true },
		name: 'Description',
		order: 22,
	})
	@linkedFormField('editDescription')
	public description: string = '';

	@formField({
		fieldtype: FieldType.Boolean,
		name: 'Tags',
		group: 'group1',
		order: 3,
	})
	public editTag: boolean = false;

	@formField({
		fieldtype: FieldType.Tags,
		flags: { multiple: true },
		order: 23,
	})
	@linkedFormField('editTag')
	public __tag: string[] = [];

	@formField({
		fieldtype: FieldType.Boolean,
		name: 'Product mapping',
		group: 'group1',
		order: 4,
	})
	public editProductMapping: boolean = false;

	@formField({
		fieldtype: FieldType.ProductData,
		name: 'Product mapping',
		flags: { required: false },
		order: 24,
	})
	@linkedFormField('editProductMapping')
	public productData: string = '';

	@formField({
		fieldtype: FieldType.Boolean,
		name: 'Exam year & series',
		group: 'group1',
		order: 5,
	})
	public editExamSeries: boolean = false;

	@formField({
		group: 'series-year',
		fieldtype: FieldType.NumberList,
		config: {
			options: generateYearRange(),
		},
		name: 'Exam year',
		order: 25,
	})
	@linkedFormField('editExamSeries')
	public exam_year?: number | number[] = undefined;

	@formField({
		group: 'series-year',
		fieldtype: FieldType.List,
		config: { options: seriesOptions },
		name: 'Series',
	})
	@linkedFormField('editExamSeries')
	public series?: string = undefined;

	@formField({
		fieldtype: FieldType.Boolean,
		name: 'Content type',
		group: 'group2',
		order: 6,
	})
	public editContentType: boolean = false;

	@formField({
		fieldtype: FieldType.Reference,
		config: { refModel: Types.CONTENT_TYPE },
		name: 'Content type',
		order: 26,
	})
	@linkedFormField('editContentType')
	public content_type: string = '';

	@formField({
		fieldtype: FieldType.Boolean,
		name: 'Date and time available on the OCR public site and to Trial Access users',
		group: 'group2',
		order: 7,
	})
	public editPubliclyAvailable: boolean = false;

	@formField({
		fieldtype: FieldType.Date,
		order: 27,
		name: 'Date and time available on the OCR public site and to Trial Access users',
	})
	@linkedFormField('editPubliclyAvailable')
	public public_from?: string = '';

	constructor(item: any = {}) {
		super(item);
	}

	public formOnRenderField(evt: FormEvent): boolean {
		const session = getRecoil(sessionAtom)
		const {fieldUID, state} = evt
		
		switch (fieldUID) {
			case 'title':
				return state.values.editTitle

			case 'description':
				return state.values.editDescription

			case '__tag':
				return state.values.editTag

			case 'productData':
				return state.values.editProductMapping

			case 'exam_year':
				const examState = state.fieldState("exam_year")
				examState.field.flags.multiple = true

				if (session?.selectedBusinessStream === OCR_STREAM) {
					examState.field.flags.multiple = false
				}

				return state.values.editExamSeries

			case 'series':
				const seriesState = state.fieldState("series")
				seriesState.field.config.options = seriesOptionsCI

				if (session?.selectedBusinessStream === OCR_STREAM) {
					seriesState.field.config.options = seriesOptions
				}

				return state.values.editExamSeries

			case 'content_type':
				return state.values.editContentType
				
			case 'public_from':
				return state.values.editPubliclyAvailable

			default:
				return true
		}
	}

	public formOnValidate(evt: FormEvent): any {
		const errors: any = {};
		// check if title is checked and has empty value
		if (evt.state.values.editTitle && !evt.state.values.title) {
			errors['title'] = 'Title is required';
		}
		// check if product data is checked and has no value
		if (evt.state.values.editProductMapping && !evt.state.values.productData) {
			errors['productData'] = 'Product mapping is required';
		}
		// check if content type is checked and has no value
		if (evt.state.values.editContentType && !evt.state.values.content_type) {
			errors['content_type'] = 'Content type is required';
		}
		if (evt.state.values.editExamsSeries) {
			const yearsOptions = generateYearRange();
			if (typeof evt.state.values.exam_year === 'number') {
				if (evt.state.values.exam_year && (+evt.state.values.exam_year < +yearsOptions[0][0]['text'] && +evt.state.values.exam_year > +yearsOptions[yearsOptions.length - 1][0]['text'])) {
					errors['exam_year'] = 'Exam year is required';
				}
			} else if (Array.isArray(evt.state.values.exam_year)) {
				if (+evt.state.values.exam_year[0] < +yearsOptions[0][0]['text'] && +evt.state.values.exam_year[evt.state.values.exam_year.length - 1] > +yearsOptions[yearsOptions.length - 1][0]['text']) {
					errors['exam_year'] = 'Exam year is required';
				}
			}

			if (evt.state.values.series && !['JANUARY', 'JUNE', 'NOVEMBER', 'MARCH'].includes(evt.state.values.series)) {
				errors['series'] = 'Series is required';
			}
		}
		return errors;
	}

	public formOnBeforeSave(evt: FormEvent): any {
		const tmp = { ...evt.state.values };
		const values = evt.state.values;

		if (!values.editTitle) {
			delete tmp.title;
		}
		delete tmp.editTitle;

		// if checkbox is not checked then delete description field (do not send it to server)
		if (!values.editDescription) {
			delete tmp.description;
		} else if (values.editDescription && !values.description) {
			tmp.description = null;
		}
		delete tmp.editDescription;

		if (!values.editTag || (values.editTag && values.__tag.length === 0)) {
			delete tmp.__tag;
		}
		delete tmp.editTag;

		if (values.editExamSeries) {
			if (!values.exam_year) {
				tmp.exam_year = null;
			}
			if (!values.series) {
				tmp.series = null;
			}
		} else {
			delete tmp.exam_year;
			delete tmp.series;
		}
		delete tmp.editExamSeries;

		if (!values.editContentType) {
			delete tmp.content_type;
		}
		delete tmp.editContentType;

		if (!values.editPubliclyAvailable) {
			delete tmp.public_from;
		} else if (values.editPubliclyAvailable && !values.public_from) {
			tmp.public_from = null;
		}
		delete tmp.editPubliclyAvailable;

		if (!values.editProductMapping) {
			delete tmp.all_assessments;
			delete tmp.all_child_assessments;
			delete tmp.assessment;
			delete tmp.child_assessment;
			delete tmp.component;
			delete tmp.ct_unit;
			delete tmp.qualification;
			delete tmp.qualification_group;
			delete tmp.qualification_size;
			delete tmp.specification_group;
			delete tmp.subject;
		} else {
			if (values.productData === 'all_assessments') {
				tmp.all_assessments = true;
				tmp.all_child_assessments = true;
				tmp.assessment = null;
				tmp.child_assessment = null;
				tmp.component = null;
				tmp.ct_unit = null;
				tmp.qualification = null;
				tmp.qualification_group = null;
				tmp.qualification_size = null;
				tmp.specification_group = null;
				tmp.subject = null;
			} else {
				tmp.all_assessments = false;
				tmp.all_child_assessments = false;
				if (tmp.assessment.length === 0) {
					tmp.assessment = null;
				}
				if (tmp.child_assessment.length === 0) {
					tmp.child_assessment = null;
				}
				if (tmp.component.length === 0) {
					tmp.component = null;
				}
				if (tmp.ct_unit.length === 0) {
					tmp.ct_unit = null;
				}
				if (tmp.qualification.length === 0) {
					tmp.qualification = null;
				}
				if (tmp.qualification_group.length === 0) {
					tmp.qualification_group = null;
				}
				if (tmp.qualification_size.length === 0) {
					tmp.qualification_size = null;
				}
				if (tmp.specification_group.length === 0) {
					tmp.specification_group = null;
				}
				if (tmp.subject.length === 0) {
					tmp.subject = null;
				}
			}
		}
		delete tmp.editProductMapping;
		delete tmp.productData;
		return tmp;
	}

	public disabledFields(): string[] {
		const state = getRecoil(selectedTableItemsSelector(Types.LIBRARY));
		const fields: string[] = [];
		// disable editContentType if at least one of the selected items is a text type
		state.objects.forEach((item) => {
			const type = item.getType()
			if (type === Types.TEXT) {
				fields.push('editContentType');
			}
		})
		return fields;
	}

}
