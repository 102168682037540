import React, {useMemo, useState} from 'react';
import {BoxContainer} from './components/BoxContainer';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {references, selectedObject} from '../../state/state';
import {CMSObject} from '../../cms/models/__CMSObject';
import {getRecoil, setRecoil} from '../../state/recoilNexus';
import {Lists} from '../../utils/lists';
import styled from '@emotion/styled';
import {fi} from '../../utils/helpers';
import CheckIcon from '@mui/icons-material/Check';
import {Status} from '../../cms/types';
import {Dates} from '../../utils/dates';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Client from '../../cms/client';
import {useSnackbar} from 'notistack';
import {confirmationModalAtom} from '../ModalDialogs/ConfirmationModal';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {ClassOf} from '../../cms/models';
import {useModalDialog} from '../ModalDialogs/effect';
import {modalAtom, ModalWrapperProps} from '../ModalDialogs/ModalWrapper';
import PublishModal from '../ModalDialogs/PublishModal';

const Row = styled.div`
    padding: 8px 16px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    column-gap: 8px;
    align-items: center;

    &:hover {
        background-color: var(--color-box-shadow)
    }

    &.selected {
        background-color: var(--color-selected);

        svg {
            color: var(--color-green);
            visibility: visible;
        }
    }

    &:hover svg {
        visibility: visible;
        color: var(--color-border);
    }

    svg {
        height: 20px;
        visibility: hidden;
    }

    .author {
        color: var(--color-border);
        font-size: 14px;
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        text-align: right;
    }
`;

const Actions = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;

    button.expand {
        padding-left: 4px !important;
        padding-right: 4px !important;
    }

    .spacer {
        flex-grow: 1;
    }
`;

const List = styled.div`
    max-height: calc(44px * 5);
    overflow: auto;
`;

const Schedule = styled.span`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;

    svg {
        visibility: visible !important;
        margin-top: 2px;
        color: var(--color-backdrop) !important;
        font-size: 16px !important;
    }
`;

const VersionBox = () => {
	Dates.useTimeZone();
	const {confirmation} = useModalDialog();
	const {enqueueSnackbar} = useSnackbar();
	// this is the currently selected version which may not be the loaded object
	const [selected, setSelected] = useRecoilState(selectedObject);
	const [showAllVersions, setShowAllVersions] = useState<boolean>(false);
	const setModalEdit = useSetRecoilState(modalAtom);

	// this is the loaded object from the server
	// the last version (draft or whatever it is)
	const base: CMSObject | null = useMemo(() => {
		if (selected) {
			const tmp: CMSObject = getRecoil(references(selected.getId()) as any);
			if (tmp) {
				const cls = ClassOf(tmp.getType());
				if (cls && !cls.autoPublish) {
					return tmp;
				}
			}
		}
		return null;
	}, [selected]);

	// this is the list of versions casted as CMSObjects take from the base object identified above
	const versions = useMemo(() => {
		const res: CMSObject[] = [];
		if (!base) {
			return res;
		}

		res.push(base);
		res.push(...[...Lists.default<CMSObject>(base.__versions)].reverse());

		return res;
	}, [base]);

	const list = useMemo(() => {
		const result: CMSObject[] = [];
		if (!base) {
			return result;
		}
		if (showAllVersions) {
			return versions;
		}
		return versions.slice(0, 1);
	}, [base, versions, showAllVersions]);

	const showDiscard = useMemo(() => {
		return base && base.isDraft() && base === selected && versions.length > 1;
	}, [base, versions, selected]);

	const showReschedule = useMemo(() => {
		return (selected?.isPublished() || selected?.isScheduled()) && !selected?.isDraft();
	}, [selected]);

	const noRestore = useMemo(() => {
		if (!base) {
			return false;
		}
		const cls = ClassOf(base!.getType());
		return Boolean(cls.noRestore);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [base, selected]);

	const getSchedule = (version: CMSObject) => {
		if (!base) {
			return null;
		}
		if (version.getMeta().version !== base.getMeta().version || version.isDraft()) {
			return null;
		}

		const meta = base.getMeta();
		const {published, isLive, scheduled} = base.publishedState();
		if (published) {
			// if published but is not scheduled to be unpublished
			if (!meta.unpublish) {
				return <>({Dates.local(meta.published!)})</>;
			}
			return <>({Dates.local(meta.published!)} <ArrowRightAltIcon /> {Dates.local(meta.unpublish!)})</>;
		}

		if (scheduled) {
			if (scheduled && isLive) {
				return <>({Dates.local(meta.published!)} <ArrowRightAltIcon /> {Dates.local(meta.unpublish!)})</>;
			} else if (scheduled && !isLive) {
				return fi(meta.unpublish !== undefined,
					<>({Dates.local(meta.published!)} <ArrowRightAltIcon /> {Dates.local(meta.unpublish!)})</>,
					<>({Dates.local(meta.published!)} <ArrowRightAltIcon /> <AllInclusiveIcon />)</>);
			}
		}
		return '';
	};

	const reload = async (id) => {
		return Client.get(id).then((res) => {
			setRecoil(selectedObject, res);
		});
	};

	const discardDraft = () => {
		if (base) {
			confirmation('Are you sure you want to discard this draft?', async () => {
				Client.discardDrafts([base.getId()]).catch(err => {
					enqueueSnackbar('Failed to discard draft: ' + err, {variant: 'error'});
				}).then(() => {
					enqueueSnackbar('Draft changes were discarded.', {variant: 'success'});
					reload(base.getId()).catch();
				});
			});
		}
	};

	const onPublishUnpublish = (reschedule: boolean = false) => {
		if (!selected) return;
		if (reschedule) {
			// show reschedule dialog
			setModalEdit({
				component: (props: ModalWrapperProps) => {
					return <PublishModal {...props} items={[selected]} />;
				},
			});
		} else {
			if (selected?.getStatus() === Status.Archived) {
				const version = selected.getMeta().version;
				// if any previous versions of the app have a missing icon file we need to remove it from the payload
				// so it will not trigger an error on the server
				let payload = {...selected}
				if ((payload as any).icon === ""){
					delete (payload as any).icon
				}

				// restore selected version
				Client.update(payload).then((res) => {
					enqueueSnackbar(`Successfully reverted content of version ${version}`, {variant: 'success'});
					setRecoil(selectedObject, res);
				}).catch(err => {
					enqueueSnackbar('Failed to restore version: ' + err, {variant: 'error'});
				});
			} else if (selected?.isDraft() || selected?.isUnpublished()) {
				// show publish dialog
				setModalEdit({
					component: (props: ModalWrapperProps) => {
						return <PublishModal {...props} items={[selected]} />;
					},
				});
			} else {
				// Unpublish selected version
				setRecoil(confirmationModalAtom, {
					message: <>Are you sure you want to unpublish <strong>{selected.displayLabel()}</strong>?</>,
					onConfirm: async () => {
						return Client.unpublish({uuids: [selected.getId()]}).then(() => {
							enqueueSnackbar('Item has been unpublished.', {variant: 'success'});
							// reload object
							reload(selected.getId()).catch();
						}).catch(err => {
							enqueueSnackbar('Failed to unpublish: ' + err, {variant: 'error'});
						});
					},
				});
			}
		}
	};

	const setPreviewVersion = (version: CMSObject) => {
		setSelected(version);
	};

	return (
		<BoxContainer title='Version history' id='version' hidden={!base}>
			<List>
				{list.map((version, idx) => (
					<Row className={fi(version === selected, 'selected')} key={idx}
						 onClick={() => setPreviewVersion(version)}>
						<span><CheckIcon data-testid='check-icon' /></span>
						<span
							data-testid='version-number'>{fi(version.isDraft(), Status.Draft, `v${version.getMeta().version}`)}</span>
						<span>{fi(!version.isDraft(), version.__statusColumn())} <Schedule
							title='Published range'>{getSchedule(version)}</Schedule></span>
						<span
							className='author'>{version.__authorColumn()}<br /> {Dates.local(version.getMeta().modified)}</span>
					</Row>
				))}
			</List>
			<Divider />
			<Actions>
				{versions.length > 1 &&
					<Button className='expand' variant='text' onClick={() => setShowAllVersions(!showAllVersions)}>
						{fi(showAllVersions, <><ExpandLess /> Hide versions</>, <><ExpandMore /> Show all versions</>)}
					</Button>}
				<div className='spacer' />
				{showDiscard && (
					<Button variant='outlined' onClick={discardDraft}>Discard draft</Button>
				)}
				{showReschedule && (
					<Button variant='outlined' onClick={() => onPublishUnpublish(true)}>Reschedule</Button>
				)}
				{fi(selected?.getStatus() === Status.Archived && noRestore, null,
					<Button variant='contained' onClick={() => onPublishUnpublish(false)}>
						{fi(selected?.getStatus() === Status.Archived, 'Restore',
							fi(selected?.isDraft() || selected?.isUnpublished(), 'Publish', 'Unpublish'),
						)}
					</Button>)}
			</Actions>
		</BoxContainer>
	);
};

export default VersionBox;