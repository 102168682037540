import React, {useEffect, useRef} from 'react';
import {breadcrumbSelection, bulkUploadScrollState, cacheBuster, cmsObjectsLoader, productDataSelector} from '../../state/state';
import { bulkTableColumnsState } from './state';
import { useRecoilValue, useSetRecoilState, useRecoilState} from 'recoil';
import BulkTable from "./BulkTable";
import styled from "@emotion/styled";
import {cancelEvent, debounce} from '../../utils/helpers';
import Client from '../../cms/client';
import { BulkFile } from '../../cms/models/BulkFile';
import { Strings } from '../../utils/strings';
import { CMSFile } from '../../cms/models/File';
import { setRecoil } from '../../state/recoilNexus';
import {sessionAtom} from "../../state/session";
import {INTERNATIONAL_STREAM} from "../../utils/constants";
import {Types} from "../../cms/types";

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  overflow: auto;
`

const DragDropIndicator = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  pointer-events: none;
  border: 2px dashed var(--color-border);
  align-items: center;
  align-content: center;
  justify-content: center;
  display: none;

  &.over {
      display: flex;
  }

  h2 {
    pointer-events: none;
    color: black;
    font-weight: bold;
    font-size: 20px;
  }
`

const BulkComponent = () => {
    const setSelection = useSetRecoilState(breadcrumbSelection);
    const setScrollState = useSetRecoilState(bulkUploadScrollState)
    const session = useRecoilValue(sessionAtom);
    const dropRef: any = useRef<any>();
    const bulkContainerRef: any = useRef<any>();

    // these are here to load the data
    useRecoilValue(productDataSelector);
    useRecoilValue(cmsObjectsLoader([Types.CONTENT_TYPE]))

    const bounce = useRef(debounce(() => {
        setScrollState(false)
    }, 50))

    useEffect(() => {
        setSelection('Bulk upload');
        return () => {
            setSelection(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onScroll = () => {
        setScrollState(true)
        bounce.current();
    }

    const onDragEnter = (event: any) => {
        dropRef.current.classList.add('over');
        event.dataTransfer.dropEffect = 'none';
    };

    const onDragLeave = (event: any) => {
        if (event.currentTarget.contains(event.relatedTarget)) return;
        dropRef.current.classList.remove('over');
    };

    const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        cancelEvent(event);
    };

    const onDrop = (event: React.DragEvent<HTMLElement>) => {
        dropRef.current.classList.remove('over');
        cancelEvent(event);
        onUploadFiles(event.dataTransfer.files);
    };

    const onUploadFiles = (list: FileList) => {
        const files: File[] = [];

        const validExtensions = [
            ...CMSFile.Extensions.PDF,
            ...CMSFile.Extensions.CSV,
            ...CMSFile.Extensions.Excel,
            ...CMSFile.Extensions.MathExcel,
            ...CMSFile.Extensions.Doc,
            ...CMSFile.Extensions.PPT,
            ...CMSFile.Extensions.ZIP,
            ...CMSFile.Extensions.Audio,
        ]

        for (let i = 0; i < list.length; i++) {
            const file = list[i];
            const ext = Strings.default(file.name.split('.').pop()).toLowerCase();
            if (validExtensions.includes(ext)) {
                files.push(file);
            }
        }

        if (files.length === 0) {
            return;
        }

        files.forEach(file => uploadFile(file));
    }

    const uploadFile = (file: File): void => {
        const businessStream = session?.selectedBusinessStream || 'default';
        if (file.name === `BulkImport-${businessStream}.xlsx`) {
            const [parseResult] = Client.parseTemplateFile(file);
            parseResult.then(excelRows => {
                BulkFile.fromTemplate(excelRows, businessStream).then
                    (templateFiles => {
                        templateFiles.forEach(templateFile => {
                            BulkFile.addOrUpdate(templateFile, (existing) => {
                                existing.filename = templateFile.filename;
                                if (existing.data.file) {
                                    templateFile.data.file = existing.data.file;
                                }
                                existing.data = templateFile.data;
                                existing.setData({}, true);
                                setRecoil(cacheBuster(`bulk-file-${templateFile.filename}`), (val) => val + 1);
                            });
                        });
                    });
            });
        } else {
            let f: BulkFile;
            if (businessStream === INTERNATIONAL_STREAM) {
                f = BulkFile.fromFileName(file.name)
            } else {
                f = new BulkFile({filename: file.name})
            }

            const newFile = BulkFile.addOrUpdate(f, existing => {
                existing.uploadFile(file);
            });
            if (newFile.bulkid < 1) {
                newFile.uploadFile(file);
            }
        }
    };

    const [tableColumns, setTableColumns] = useRecoilState(bulkTableColumnsState)

    useEffect(() => {
        const updatedTableColumns = tableColumns.filter(column => {
            if (session?.selectedBusinessStream !== INTERNATIONAL_STREAM) return column.name !== 'paper_number'
            return column
        })
        setTableColumns(updatedTableColumns)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session?.selectedBusinessStream])


    return (
        <Wrapper onDragEnter={onDragEnter} onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop} ref={bulkContainerRef} onScroll={onScroll} data-testid='bulk-component'>
            <BulkTable onUpload={onUploadFiles} columns={tableColumns}/>
            <DragDropIndicator ref={dropRef}>
                <h2>Drop your files here to be uploaded</h2>
            </DragDropIndicator>
        </Wrapper>
    );
};

export default BulkComponent;
