import {CMSObject} from "../../cms/models/__CMSObject";
import {Types, UUID} from "../../cms/types";
import {ClassOf, InstanceOf} from "../../cms/models";
import Client from "../../cms/client";
import {Field, FieldType, ModelInfo} from "../../cms/models/__ModelInfo";
import {Objects} from "../../utils/objects";
import {FormContextType} from "./Form";
import {fi} from "../../utils/helpers";
import {getFormConfig} from "../../utils/decorators";
import {setRecoil} from "../../state/recoilNexus";
import {cacheBuster, selectedObject, uploadedFile} from '../../state/state';
import {ISelectValue} from "./renderers/components/Select/SelectComponent";
import {BaseWidget} from "../../cms/models/PageWidget";
import {preventReloadAtom} from "../commons/PreventReload";
import {Strings} from "../../utils/strings";
import {Messages} from '../../utils/messages';

export enum FormEventType {
    OnBeforeLoad,
    OnLoaded,
    OnLoadError,
    OnRender,
    OnRenderField,
    OnBeforeFieldChange,
    OnFieldChange,
    OnValidateField,
    OnValidate,
    OnValidateError,
    OnValidateSuccess,
    OnBeforeSave,
    OnSaveError,
    OnSaved,
    OnLeave,
    OnFilterDataset,
    OnGroupValidation
}

type FormStateKey = {
    formId: string;
    type: string;
    objectUID: string;
}

export class FormState {
    static formStates: { [key: string]: FormState } = {};
    public _guid: string = crypto.randomUUID();
    public context: any
    // unique form id, used as atom key
    public id: string;
    // object type being edited
    public type: Types = Types.UNDEFINED;
    // original object data being edited
    public data: { [key: string]: any } = {};
    // edited object values that will be saved
    public values: { [key: string]: any } = {};
    // form errors maped as fieldUID: error message
    public errors: { [key: string]: string } = {};
    // flags indicating form fields that were edited by the user
    public touched: { [key: string]: boolean } = {};

    public parentForm: FormState | undefined = undefined;

    public createMode: boolean = false;
    public previewMode: boolean = false;

    public object: CMSObject = new CMSObject();
    public model: ModelInfo = new ModelInfo({});

    public meta: any = undefined;

    private instance: IFormEvents = {};

    private noPreventReload: boolean;

    private fieldStates: { [key: string]: FieldState } = {};

    public missingLinks: { [key: string]: number } = {};
    private timer: any;
    private checking: boolean = false;

    public static getFormState(context: FormContextType): FormState {
        const key: FormStateKey = {
            formId: context.id,
            type: context.model.getType(),
            objectUID: context.uid,
        }
        return FormState.formStates[JSON.stringify(key)];
    }

    constructor(context: FormContextType) {
        window['formStates'] = FormState.formStates
        this.context = context;
        this.parentForm = context.parentForm;
        this.noPreventReload = Boolean(context.noPreventReload);

        const key: FormStateKey = {
            formId: context.id,
            type: context.model.getType(),
            objectUID: context.uid,
        }

        this.id = JSON.stringify(key);
        const existing = FormState.formStates[this.id]
        if (existing) {
            return existing;
        }

        this.createMode = Boolean(context.createMode);
        this.previewMode = Boolean(context.previewMode);
        this.type = context.model.getType();
        const formConfig = getFormConfig(this.type);
        this.model = formConfig.model;

        this.object = InstanceOf(this.type);
        this.instance = this.object as IFormEvents;

        FormState.formStates[this.id] = this;
        this.settle(false);
    }

    public setObject(object: CMSObject | object) {
        if (object instanceof CMSObject || object instanceof BaseWidget) {
            this.trigger(FormEventType.OnBeforeLoad, {uuid: object.getId()});
            this.object = object as any;
        } else {
            this.trigger(FormEventType.OnBeforeLoad, {});
            this.object = new CMSObject(object);
        }

        this.instance = this.object as IFormEvents;
        const key: FormStateKey = {
            formId: this.context.id,
            type: this.model.getType(),
            objectUID: this.object.getId(),
        }

        this.id = JSON.stringify(key);
        FormState.formStates[this.id] = this;

        setRecoil(cacheBuster(this.id), (val) => val + 1);

        this.settle(true);
        this.trigger(FormEventType.OnLoaded);
    }

    public async load(uuid: UUID): Promise<void> {
        if (!uuid) {
            return
        }
        this.trigger(FormEventType.OnBeforeLoad, {uuid: uuid});
        try {
            this.object = await Client.get(uuid);
            this.instance = this.object as IFormEvents;
            this.settle();
            this.trigger(FormEventType.OnLoaded);
        } catch (e) {
            this.trigger(FormEventType.OnLoadError, {error: e});
            throw e;
        }
    }

    public getSaveObject(): any {
        if (!this.validate(this.values)) {
            throw new Error(Messages.FixErrors);
        }
        let data = {...this.values};
        data = Objects.default(this.trigger(FormEventType.OnBeforeSave), data);

        data['__type'] = this.type;
        if (this.object.getId()) {
            data['__id'] = this.object.getId();
        }

        return data;
    }

    public async save(): Promise<CMSObject> {
        try {
            const data = this.getSaveObject();
            if (data['__id']) {
                this.object = await Client.update(data)
            } else {
                this.object = await Client.create(data)
            }
            this.instance = this.object as IFormEvents;
        } catch (e) {
            const err = Objects.default(this.trigger(FormEventType.OnSaveError, {error: e}), e);
            return Promise.reject(err);
        }

        setRecoil(selectedObject, this.object);
        setRecoil(preventReloadAtom, false)

        this.trigger(FormEventType.OnSaved);
        return this.object;
    }

    public classOf(): any {
        return ClassOf(this.type)
    }

    public fieldState(fieldUID: string): FieldState {
        return this.fieldStates[fieldUID]
    }

    public hasChanges(): boolean {
        return !Objects.same(this.data, this.values);
    }

    public setMeta(val: any): void {
        this.meta = val;
    }

    public hasErrors(): boolean {
        return Object.keys(this.errors).length > 0;
    }

    public trigger(eventType: FormEventType, data: any = {}): any {
        let method: string = '';
        switch (eventType) {
            case FormEventType.OnBeforeLoad:
                method = 'formOnBeforeLoad';
                break;
            case FormEventType.OnLoaded:
                method = 'formOnLoaded';
                break;
            case FormEventType.OnLoadError:
                method = 'formOnLoadError';
                break;
            case FormEventType.OnRender:
                method = 'formOnRender';
                break;
            case FormEventType.OnRenderField:
                method = 'formOnRenderField';
                break;
            case FormEventType.OnBeforeFieldChange:
                method = 'formOnBeforeFieldChange';
                break;
            case FormEventType.OnFieldChange:
                method = 'formOnFieldChange';
                break;
            case FormEventType.OnValidateField:
                method = 'formOnValidateField';
                break;
            case FormEventType.OnGroupValidation:
                method = 'formOnGroupValidation';
                break;
            case FormEventType.OnValidate:
                method = 'formOnValidate';
                break;
            case FormEventType.OnValidateError:
                method = 'formOnValidateError';
                break;
            case FormEventType.OnValidateSuccess:
                method = 'formOnValidateSuccess';
                break;
            case FormEventType.OnBeforeSave:
                method = 'formOnBeforeSave';
                break;
            case FormEventType.OnSaveError:
                method = 'formOnSaveError';
                break;
            case FormEventType.OnSaved:
                method = 'formOnSaveSuccess';
                break;
            case FormEventType.OnLeave:
                method = 'formOnLeave';
                break;
            case FormEventType.OnFilterDataset:
                method = 'formOnFilterDataset'
                break;
            default:
                return;
        }
        if (this.instance[method]) {
            return this.instance[method]({type: eventType, state: this, ...data});
        }
    }

    public setValues(values: any): void {
        //console.log(`Form.setValues(${JSON.stringify(values)})`);
        for (let k in values) {
            this.setValue(k, values[k]);
        }
    }

    public setValue(fieldUID: string, value: any): void {
        const field = this.model.getField(fieldUID)
        if (field) {
            if (field?.fieldtype === FieldType.Boolean && typeof value === 'string') {
                value = true.toString() === value
            }
            const validValue = this.trigger(FormEventType.OnBeforeFieldChange, {fieldUID, fieldValue: value})
            if (typeof validValue !== 'undefined' && !validValue) {
                if (!this.noPreventReload) {
                    setRecoil(preventReloadAtom, this.hasChanges())
                }
                return
            }
        }
        const formField = this.model.getField(fieldUID);
        if (formField) {
            this.values[fieldUID] = value;
            this.touched[fieldUID] = true;
            const fieldError = this.trigger(FormEventType.OnValidateField, {fieldUID, fieldValue: value})
            if (fieldError) {
                this.errors[fieldUID] = fieldError
            } else {
                this.errors[fieldUID] = formField.validate(value);
            }
            const groupErrors = this.trigger(FormEventType.OnGroupValidation, {fieldUID, fieldValues: this.values})
            if (groupErrors) {
                let difference = Object.keys(this.errors).filter(x => this.errors[x] !== groupErrors[x] );
                this.setErrors(groupErrors)
                difference.forEach((k) => {
                    this.fieldState(k).refresh()
                })
            }

            this.fieldState(fieldUID).refresh()
        }

        this.values = {...this.values};
        this.trigger(FormEventType.OnFieldChange, {fieldUID, fieldValue: value});

        // check fields that may be linked with this one
        this.model.fields.forEach((field) => {
            if (field.config.linkedWith === fieldUID) {
                this.fieldState(field.uid).refresh()
            }
        })
        if (!this.noPreventReload) {
            setRecoil(preventReloadAtom, this.hasChanges())
        }
    }

    public setError(fieldUID: string, error: any): void {
        //console.log(`FormState.setError(${fieldUID}, ${error})`)
        this.errors[fieldUID] = error;
        this.touched[fieldUID] = true;
        this.fieldState(fieldUID).refresh()
    }

    public setErrors(errors: any): void {
        Object.keys(Objects.default(errors)).forEach((fieldUID) => {
            this.setError(fieldUID, errors[fieldUID])
        })
    }

    public clearFields(fields: any): void {
        //console.log(`FormState.clearFields(${JSON.stringify(fields)})`)
        for (let k in fields) {
            this.clearField(k, fields[k]);
        }
    }

    public clearField(fieldUID: string, value: any): void {
        //console.log(`FormState.clearField(${fieldUID})`)
        this.values[fieldUID] = value;
        this.errors[fieldUID] = '';
        this.touched[fieldUID] = false;
        this.fieldState(fieldUID).refresh()
        if (!this.noPreventReload) {
            setRecoil(preventReloadAtom, this.hasChanges())
        }
    }

    public validate(data?: any): boolean {
        if (!data) {
            data = {...this.values}
        }
        const modelValidation = this.model.validate(data)
        let errors = Objects.default(this.trigger(FormEventType.OnValidate))
        errors = {...errors, ...modelValidation}
        if (Object.keys(errors).length > 0) {
            this.errors = errors;
            this.setErrors(errors)
            this.trigger(FormEventType.OnValidateError);

            return false
        }

        this.trigger(FormEventType.OnValidateSuccess);
        return true;
    }

    public validateField(fieldUID: string, data?: any): string {
        const field = this.model.getField(fieldUID);
        if (field) {
            if (field.fieldtype === FieldType.File) {
                return field.validateFile(data)
            }
            return field.validate(data)
        }
        return '';
    }

    public refresh(): void {
        setRecoil(cacheBuster(this.id), (val) => val + 1)
    }

    public destroy() {
        delete (FormState.formStates[this.id]);
        setRecoil(uploadedFile, null)
        clearTimeout(this.timer);
    }

    public onBeforeRenderField(fieldUID: string): boolean {
        const out = this.trigger(FormEventType.OnRenderField, {fieldUID})
        return fi(typeof out === 'undefined', true, out);
    }

    public filterDataSet(fieldUID: string, dataset: ISelectValue[]): ISelectValue[] {
        const out = this.trigger(FormEventType.OnFilterDataset, {fieldUID, dataset})
        return fi(typeof out === 'undefined', dataset, out)
    }

    private settle(refresh: boolean = true) {
        if (this.object.toJSON) {
            this.data = Objects.default(this.object.toJSON());
        } else {
            this.data = {}
        }
        this.values = {...this.data};
        this.errors = {};
        this.touched = {};
        this.missingLinks = {};
        this.checkMissingLinks();

        this.model.fields.forEach((field: Field) => {
            if (!this.fieldStates[field.uid]) {
                this.fieldStates[field.uid] = new FieldState(this, field.uid);
            }
            if (refresh) {
                this.fieldStates[field.uid].refresh();
            }
        });
    }

    private checkMissingLinks(): void {
        const model = this.model.getType();
        const models = [Types.LINK, Types.TEXT, Types.SUBJECT_UPDATES, Types.USEFUL_LINK, Types.VIDEO];
        if (!models.includes(model)) {
            return
        }
        if (!this.context.uid || this.checking) {
            return
        }
        this.checking = true;
        Client.missingLinksCount(this.context.uid).then((res) => {
            this.missingLinks = Objects.default(res);
            if (!Object.keys(this.missingLinks).length) {
                clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    this.checkMissingLinks()
                }, 10000)
            } else {
                Object.values(this.fieldStates).forEach((s) => {
                    s.refresh()
                })
            }
        }).catch(() => {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.checkMissingLinks()
            }, 10000)
        }).finally(() => {
            this.checking = false;
        })
    }
}


export class FieldState {
    // field unique id
    public uid: string;
    // field definition
    public field: Field;
    // original value loaded from server
    public data: any;
    // current value from the form
    public value: any;
    // flag indicating the object was edited
    public touched: boolean = false;
    // error message
    public error: string = '';

    public disabled: boolean|undefined = undefined;

    // reference to the form state
    public readonly state: FormState;

    constructor(state: FormState, fieldUID: string) {
        this.state = state;
        this.uid = fieldUID;
        this.field = state.model.getField(fieldUID) as Field;
        this.refresh();
    }

    public setValue(value: any): void {
        this.state.setValue(this.uid, value);
    }

    public setError(error: any): void {
        this.state.setError(this.uid, error);
    }

    public showError(): boolean {
        return Boolean(this.error) && this.touched;
    }

    // refreshes the internal state of the FieldState from the FormState and invalidates
    // the `cacheBuster` atom for this field so the component re-renders
    public refresh() {
        //console.log(`FieldState[${this.state.id}/${this.uid}].refresh()`)
        this.data = this.state.data[this.uid];
        this.value = this.state.values[this.uid];
        this.touched = this.state.touched[this.uid];
        this.error = this.state.errors[this.uid];

        setRecoil(cacheBuster(`${this.state.id}/${this.uid}`), (val) => val + 1)
    }

    public filterDataSet(dataset: ISelectValue[]): ISelectValue[] {
        return this.state.filterDataSet(this.uid, dataset);
    }

    // This method returns true if the form can render the current field
    // The value is retrieved from the class being edited if it implements
    // `formOnRenderField` method
    public canRender(): boolean {
        return this.state.onBeforeRenderField(this.uid);
    }

    public getWarning(): any {
        let warning: string[] = [];
        const val = this.decode(Strings.default(this.value).toString())
        Object.keys(this.state.missingLinks).forEach((link) => {
            if (val.includes(this.decode(link))) {
                const status = this.state.missingLinks[link];
                let err: string;
                switch (status) {
                    case 404:
                        err = 'returns NOT FOUND'
                        break;
                    case 403:
                        err = 'could not be checked. Returns FORBIDDEN'
                        break
                    case 400:
                        err = 'could not be checked. Returns BAD REQUEST'
                        break
                    case 500:
                        err = 'could not be checked. Returns INTERNAL SERVER ERROR'
                        break
                    default:
                        err = 'could not be checked'
                }
                warning.push(`${link} ${err}`);
            }
        })
        if (warning.length) {
            return warning;
        }
        return null
    }

    private decode(str: string): string {
        if (!str) return ''
        let txt = document.createElement("textarea");
        txt.innerHTML = str;
        try {
            return decodeURIComponent(txt.value);
        } catch (e) {
            return txt.value;
        }
    }
}

export type FormEvent = {
    type: FormEventType;
    state: FormState;
    uuid?: string;
    error?: any;
    fieldUID?: string;
    object: any;
    dataset?: ISelectValue[];
    fieldValue?: any;
    fieldValues?: any;
    hasChanges?: boolean;
}

export interface IFormEvents {
    [key: string]: any; // index signature to solve the fact that all methods here are optional

    // Form will create a new instance of the object type being edited and call onBeforeLoad on it.
    // If it will be a 'Create' form, then uuid will be empty, for 'Edit' form the uuid will be the id of
    // the object being edited.
    formOnBeforeLoad?(evt: FormEvent): void;

    // Form will load the object from the server and instantiate it and call form onLoad on it.
    // If the object implements formOnLoad then it should return the object that will be used for editing.
    formOnLoaded?(evt: FormEvent): any;

    // If object failed to load from the server, form will call formOnError on the initial object instance.
    formOnLoadError?(evt: FormEvent): void;

    // Called when the form starts rendering
    formOnRender?(): void;

    // Called before the form fields is rendered. If the method return false, the form will not be rendered.
    formOnRenderField?(evt: FormEvent): boolean;

    // Method will be called before the form field change is applied. The method shoudl return true if the change
    // should be applied or false if the change should not be applied.
    formOnBeforeFieldChange?(evt: FormEvent): boolean;

    // Method will be called when the form field value is changed.
    formOnFieldChange?(evt: FormEvent): void;

    // Fields are validated individually when their value is changes. After the value is changed, the form will call
    // this method to validate the new field value.
    formOnValidateField?(evt: FormEvent): string | void;

    // Method will be called before the form is submitted. The method should return an error object if there are any errors
    // or nothing if the form is valid.
    formOnValidate?(evt: FormEvent): any | void;

    // Method will be called when the form is submitted but there are errors on it.
    formOnValidateError?(errors: any): void;

    // Method be called before the form is submitted and there are no validation errors.
    formOnValidateSuccess?(): void;

    // Before sendind the form data to the server, a call to formOnBeforeSend will be made. If object implements formOnBeforeSend
    // then it must return the object that will be used for saving.
    formOnBeforeSave?(evt: FormEvent): any;

    // Method will be called when the form saving returns an error
    formOnSaveError?(evt: FormEvent): Error | void;

    // After the form saves the object, the returned object will be instantiated and will replace the original form object
    // after which the form will call formOnSaveSuccess on the newly created object.
    formOnSaveSuccess?(): any;

    // Method will be called before user is trying to leave the current page
    formOnLeave?(hasChanges: boolean): void;

    // Method will be called with a dataset to be filtered by the class if required
    // For example the dataset from a drop-down list may be filtered by the value from another
    formOnFilterDataset?(evt: FormEvent): ISelectValue[];
}


