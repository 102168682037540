export type UUID = string;

// List of all CMS models
export enum Types {
	UNDEFINED = '',
	FOLDER = 'folder',
	CONTENT_TYPE = 'content_type',
	DOCUMENT = 'document',
	IMAGE = 'image',
	TEXT = 'text',
	VIDEO = 'video',
	LINK = 'link',
	AUDIO = 'audio',
	EVENT_MATERIAL = 'event_material',
	BUSINESS_STREAM = 'business_stream',
	QUALIFICATION_GROUP = 'qualification_group',
	QUALIFICATION = 'qualification',
	QUALIFICATION_SIZE = 'qualification_size',
	PATHWAY = 'pathway',
	CT_UNIT = 'ct_unit',
	SPECIFICATION_GROUP = 'specification_group',
	SUBJECT = 'subject',
	EVENT = 'events',
	// CERTIFICATION = 'certification',
	COMPONENT = 'component',
	// TIER = 'tier',
	CHILD_ASSESSMENT = 'child_assessment',
	ASSESSMENT = 'assessment',
	FILE = 'file',
	USEFUL_LINK = 'useful_link',
	CONTENT_GROUP = 'content_group',
	// PAGE_TEMPLATE = 'page_template',
	PAGE = 'page',
	QUALIFICATION_GROUP_MAPPING = 'qualification_group_mapping',
	SUBJECT_GROUP_MAPPING = 'qualification_mapping',
	SUBJECT_UPDATES = 'subject_information',
	NOTIFICATIONS = 'notification',
	KEY_DATE = 'key_date',
	TIMETABLE = 'timetable_date',
	KEY_DATES_FILE = 'key_dates_file',
	ITEM_NOTE = 'note',
	EMAIL_UPDATES = 'subject_email',
	TOPIC = 'topic',
	HELP_INFO = 'help_info',
	CONTENT_CATEGORY = 'content_category',

	EXTENDED_LIBRARY = 'extended_library',
	LIBRARY = 'library',
	PRODUCT_MAPPING = 'product',
	PUBLISH_SCHEDULE = 'publish_schedule',
	BULK_EDIT = 'bulk_edit',
	SCHEDULE_EMAIL = 'schedule_email',
	PREVIEW_MAIl = 'preview_mail',
	BULK_FILE = 'bulk_file',
	MOVE_ITEMS = 'move_items',
	TILE_CONTENT = 'tile_content',

	CONTENT_GROUP_WIDGET = `content_group_widget`,
	CONTENT_TYPE_WIDGET = `content_type_widget`,
	CONTENT_ITEMS_WIDGET = `content_items_widget`,
	CHILD_PAGES_WIDGET = `child_pages_widget`,
	FAQ_WIDGET = `faq_widget`,
	EVENTS_WIDGET = `events_widget`,
	QUALIFICATION_FILTER_WIDGET = `qualifications_widget`,
	YEAR_FILTER_WIDGET = `years_widget`,
	EVENTS_FILTER_WIDGET = `month_and_year_widget`,
	FREE_TEXT_WIDGET = `text_widget`,
	USEFUL_LINKS_WIDGET = `links_widget`,
	HOMEPAGE_RESOURCES_WIDGET = `home_page_resources_widget`,
	HOMEPAGE_WIDGET = `home_page_widget`,
	SUBJECT_UPDATES_WIDGET = `subject_update_widget`,
	GLOBAL_ANNOUNCEMENTS_WIDGET = `global_announcements_widget`,
	RESOURCE_FINDER_WIDGET = `resource_finder_widget`,
	KEY_DATES_WIDGET = `key_dates_widget`,
	SIZE_PATHWAY_WIDGET = `size_pathway_widget`,
	FORM_WIDGET = `form_widget`,
	QUICK_LINKS_WIDGET = `quick_links_widget`,
	MY_SUBJECTS_WIDGET = `my_subjects_widget`,
	SUBJECTS_LIST_WIDGET = `subjects_list_widget`,
	PATHWAY_WIDGET = `pathway_widget`,
	PATHWAY_CONFIG = `pathway_config`,
}

export const SyntheticTypes: Types[] = [
	Types.PUBLISH_SCHEDULE,
	Types.CONTENT_GROUP_WIDGET,
	Types.CONTENT_TYPE_WIDGET,
    Types.CONTENT_ITEMS_WIDGET,
    Types.CHILD_PAGES_WIDGET,
    Types.FAQ_WIDGET,
    Types.EVENTS_WIDGET,
	Types.QUALIFICATION_FILTER_WIDGET,
    Types.YEAR_FILTER_WIDGET,
    Types.EVENTS_FILTER_WIDGET,
    Types.FREE_TEXT_WIDGET,
    Types.USEFUL_LINKS_WIDGET,
	Types.HOMEPAGE_RESOURCES_WIDGET,
    Types.HOMEPAGE_WIDGET,
    Types.GLOBAL_ANNOUNCEMENTS_WIDGET,
    Types.RESOURCE_FINDER_WIDGET,
    Types.MY_SUBJECTS_WIDGET,
    Types.SUBJECT_UPDATES_WIDGET,
    Types.KEY_DATES_WIDGET,
    Types.SIZE_PATHWAY_WIDGET,
	Types.FORM_WIDGET,
    Types.QUICK_LINKS_WIDGET,
	Types.BULK_EDIT,
	Types.BULK_FILE,
	Types.SCHEDULE_EMAIL,
	Types.PREVIEW_MAIl,
	Types.MOVE_ITEMS,
	Types.PATHWAY_WIDGET,
	Types.PATHWAY_CONFIG,
	Types.SUBJECTS_LIST_WIDGET,
	Types.TILE_CONTENT
]

export type Filter = number

// Content filter
export enum Filters {
	// NONE = 0 as Filter,
	// DELETED = 1 as Filter,
	ARCHIVED = 2 as Filter,
	PRIVATE = 4 as Filter,
	UNPUBLISHED = 8 as Filter,
	// ALL = Filters.DELETED | Filters.ARCHIVED | Filters.PRIVATE | Filters.UNPUBLISHED,
}

export type Sort = 'asc' | 'desc';

// The structure used for querying CMS objects
export type QueryContentRequest = {
	// Optional. If sent, the server will only compare if your request will return the same
	// result in which case it will return a 304 Not Modified response.
	// ETags and the request associated to it are stored in the 'queryETags' atomFamily.
	// The response matching a certain ETag is stored in 'cachedQueryResults' atomFamily.
	etag?: string;
	// Offset to start result set (page) from
	start?: number;
	// Number of items to return for page. -1 means no limit and will return all items starting from 'start'.
	limit?: number;
	// Field uid to be used for sorting
	order?: string;
	// Sort method. ASC or DESC
	sort?: Sort;
	// If uuids is provided, the result will only include items from this list.
	// Usually used if you have a bunch of references, and you want the CMS objects for them.
	uuids?: string[];
	// Model types to consider
	types: string[];
	// Do not retrieve metadata
	nometa?: boolean;
	// NoIndex will disable index lookup for the matching criteria
	noindex?: boolean;
	// Content filter
	filter?: Filter;
	// Include and resolve object references
	references?: boolean;
	// search query
	query?: string;
	// If set to true, the CMS will only return cached metadata about the objects and no other fields
	metadataonly?: boolean;
	// Objects whose fields match this values
	matching?: { [key: string]: any };
	withindex?: { [key: string]: any };
	mode?: string;
}

export type QueryDistinctRequest = {
	field: string;
	// Model types to consider
	types: string[];
	// UUIDs will only return items with UUIDs from this list
	uuids?: string[];
	// Content filter
	filter?: Filter;
	// Objects whose fields match this values
	matching?: { [key: string]: any };
	withindex?: { [key: string]: any };
}

export type IQueryResult<T> = {
	results: T[];
	references?: { [key: UUID]: any }
	offset: number;
	total: number;
	error?: string;
	status?: number;
	etag?: string;
}

export type IDistinctResult = {
	results: any[];
	references?: { [key: UUID]: any }
}

export type LocalizedText = {
	lang: string;
	text: string;
}

export type LocalizedString = LocalizedText[];

export type UsageStatus = {
	items_count: number,
	status: boolean;
}

export enum Status {
	Unknown = '-',
	Published = "Published",
	Scheduled = "Scheduled",
	Draft = 'Draft',
	Unpublished = 'Unpublished',
	DraftScheduled = 'Draft (Scheduled)',
	DraftPublished = 'Draft (Published)',
	Archived = 'Archived',
}

export enum Source {
	Contenful = "CONTENTFUL",
	Eps = "EPS",
	None = ""
}
