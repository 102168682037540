import {DefaultValue, selectorFamily} from 'recoil';
import {ClassOf, InstanceOf} from '../../cms/models';
import {CMSObject} from '../../cms/models/__CMSObject';
import {FieldType} from '../../cms/models/__ModelInfo';
import {Sort} from '../../cms/types';
import {cacheBuster} from '../../state/state';
import {DEFAULT_PAGE_SIZE} from '../../utils/constants';
import {getTableConfig, isColumnIgnored, isColumnHidden} from '../../utils/decorators';
import {fi} from '../../utils/helpers';
import {Lists} from '../../utils/lists';
import {Numbers} from '../../utils/numbers';
import {Objects} from '../../utils/objects';
import {Strings} from '../../utils/strings';
import {TableColumnDefinition, TableConfig} from './config';
import {ColumnType} from './renderers';
import {tableStateAtom} from './state';
import React from 'react';
import { getRecoil } from '../../state/recoilNexus';
import { sessionAtom } from '../../state/session';

export type ColumnPreference = {
	field: string, // Column id
	width: number, // 0 means hidden, -1 means auto, any other value represents the number of pixels for the column
	filter?: any,
}

// This object is persisted on localStorage to keep track of what was selected in a specfic table
// and what the state of all the buttons and filters were.
export type TablePreferences = {
	page: number, // What page the table was on. This resets if path is different.
	limit: number, // What number of items to show per page.
	sort: Sort, // What was the sort order of the table.
	order: string, // What field was the sort order of the table.
	path: string, // This is required because on media library, the page number must reset if changing the folder.
	columns: ColumnPreference[] // What columns to display, in which order and how to filter them
}

export const tablePreferencesSelector = selectorFamily<TablePreferences, string>({
	key: 'tablePreferences',
	get: (tableType) => async ({get}) => {
		get(cacheBuster(tableType));

		const tableConfig = await get(tableConfigSelector(tableType));

		let mustSave = false;
		const data = window.localStorage.getItem(tableType);
		if (!data) {
			mustSave = true;
		}
		const storedConfig = JSON.parse(Strings.default(data, '{}'));

		const preferences: TablePreferences = {
			page: Numbers.default(storedConfig.page, 0),
			limit: Numbers.default(storedConfig.limit, DEFAULT_PAGE_SIZE),
			order: Strings.default(storedConfig.order, ''),
			sort: Strings.default(storedConfig.sort, 'asc') as Sort,
			path: Strings.default(storedConfig.path, document.location.pathname),
			columns: Lists.default(storedConfig.columns, []),
		};

		Lists.default<TableColumnDefinition>(tableConfig.columns).forEach(column => {
			const current = preferences.columns.find(c => c.field === column.field);
			if (!current) {
				preferences.columns.push({
					field: column.field,
					width: fi(Boolean(column.default), -1, 0),
				});
				mustSave = true;
			}
		});

		Lists.default<ColumnPreference>(preferences.columns).forEach((column, index) => {
			const current = Lists.default<TableColumnDefinition>(tableConfig.columns).find(c => c.field === column.field);
			if (!current) {
				preferences.columns.splice(index, 0);
			}
		});

		if (mustSave) {
			window.localStorage.setItem(tableType, JSON.stringify(preferences));
		}

		return preferences;
	},
	set: (tableType) => ({reset, set}, value) => {
		if (!value || value instanceof DefaultValue) {
			window.localStorage.removeItem(tableType);
			return;
		}
		set(tableStateAtom(tableType), (state) => ({...Objects.default(state), preferences: value}));
		window.localStorage.setItem(tableType, JSON.stringify(value));
	},
});

export const tableConfigSelector = selectorFamily<TableConfig, string>({
	key: 'tableConfig',
	get: (tableType) => async () => {
		const res: TableConfig = {
			tableType: tableType,
			title: '',
			itemTypes: [tableType],
			hideTableButtons:false,
			hideActionColumn:false,
			columns: [],
		};
		let columns: TableColumnDefinition[] = [];
		let manualPublish = false;
		const loads: Promise<void>[] = [];
		const cls = ClassOf(tableType, false);
		// read a predefined configuration
		const config = getTableConfig(InstanceOf(tableType));
		if (config) {
			if (typeof config.initialLoad !== 'undefined') {
				loads.push(config.initialLoad());
			}
			if (config.title) {
				res.title = config.title;
			}
			config.additionalColumns = Lists.default<TableColumnDefinition>(config.additionalColumns);

			if (config.additionalActions) {
				res.additionalActions = Lists.default<React.ReactNode>(config.additionalActions);
			}

			if (config.additionalButtons) {
				res.additionalButtons = Lists.default<React.ReactNode>(config.additionalButtons);
			}

			if (config.itemTypes) {
				res.itemTypes = config.itemTypes;
				config.itemTypes.forEach(i => {
					const cls = ClassOf(i);
					if (!cls.autoPublish) {
						manualPublish = true;
					}
				});
			}
		}
		if (!cls) {
			return res
		}
		const instance: CMSObject = InstanceOf(tableType);
		res.title = Strings.default(res.title, cls.title);

		const model = await instance.modelInfo();
		if (!manualPublish) {
			manualPublish = !cls.autoPublish;
		}

		// If there are no columns defined for this type, we need to rely on the model fields and
		// define table columns based on those
		if (Lists.default(Objects.default(config).columns).length === 0) {
			model.fields.forEach((field, index) => {
				if (isColumnIgnored(instance, field.uid)) {
					return;
				}
				if (field.flags.hidden) {
					return;
				}
				let sortable: boolean = true;
				let type: ColumnType = ColumnType.Text;

				switch (field.fieldtype) {
					case FieldType.Text:
						type = ColumnType.Text;
						if (field.flags.multiple) {
							type = ColumnType.List;
							sortable = false;
						}
						if (field.config.isURL) {
							type = ColumnType.Link;
						}
						if (field.config.isMarkdown || field.config.isHTML) {
							return;
						}
						break;
					case FieldType.List:
						type = ColumnType.List;
						sortable = false;
						break;
					case FieldType.Number:
						type = ColumnType.Number;
						break;
					case FieldType.Date:
						type = ColumnType.Date;
						break;
					case FieldType.Boolean:
						type = ColumnType.Boolean;
						break;
					case FieldType.Reference:
						type = ColumnType.Reference;
						sortable = false;
						break;
					case FieldType.File:
						type = ColumnType.File;
						columns.push({order: index, type, label: 'File name', field: field.uid, default: true});
						return;
				}
				columns.push({
					order: index,
					type,
					sortable,
					label: field.name,
					field: field.uid,
					default: !isColumnHidden(instance, field.uid),
				});
			});
		}

		// iterate each column and override properties that may be defined in sub-types of the table
		if (config) {
			Lists.default<string>(config.itemTypes).forEach(i => {
				const typeConfig: any = getTableConfig(InstanceOf(i));
				if (typeof typeConfig.initialLoad !== 'undefined') {
					loads.push(typeConfig.initialLoad());
				}
				if (typeConfig) {
					const typeColumnOverrides = Lists.default<TableColumnDefinition>(typeConfig.additionalColumns);
					typeColumnOverrides.forEach(o => {
						const existing = columns.find(c => c.field === o.field);
						if (existing) {
							columns[columns.indexOf(existing)] = {
								...existing,
								...o,
								default: isColumnHidden(InstanceOf(i), existing.field) || existing.default,
							};
						} else {
							columns.push(o);
						}
					});
				}
			});
		}

		if (Lists.default(Objects.default(config).additionalColumns).length !== 0) {
			Lists.default<TableColumnDefinition>(config.additionalColumns).forEach(newCol => {
				const existing = columns.find(col => col.field === newCol.field);
				if (existing) {
					columns[columns.indexOf(existing)] = {...existing, ...newCol};
				} else {
					columns.push(newCol);
				}
			});
		}

		// Reminder: Remove this filter when CI will enable `public_from` field again in the future
		const session = getRecoil(sessionAtom);
		if (session?.selectedBusinessStream === 'International') {
			columns = columns.filter(c => c.field !== 'public_from');
		}

		Lists.sort(columns, 'order');

		res.manualPublish = manualPublish;
		res.columns = columns;
		res.hideTableButtons = config.hideTableButtons;
		res.hideActionColumn = config.hideActionColumn;

		if (loads.length > 0) {
			await Promise.all(loads);
		}

		return res;
	},
});
