import {FormState} from "./model";
import GroupRenderer from "./renderers/GroupRenderer";
import FormRow from "./FormRow";
import React from "react";

export const RenderFormFields = (state: FormState, hideList?: string[]): any => {
    const groups: string[] = [];
    if (!state) {
        return []
    }

    return state.model.fields.map((field) => {
        if (hideList && hideList.includes(field.uid)) {
            // setting target field hidden flag to true
            // returning null here does not prevent the field from being rendered
            field.flags.hidden = true;
        }

        if (field.flags.hidden) {
            return null;
        }

        if (field.group) {
            if (!groups.includes(field.group)) {
                groups.push(field.group);
                return <GroupRenderer key={`${state.id}/${field.group}`} group={field.group} state={state}/>
            }
            return null;
        }
        return <FormRow key={`${state.id}/${field.uid}`} field={field.uid}/>
    })
}
